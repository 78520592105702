<template>
   <MobileHeader  :mobileHeaderTitle="'NEWS'" ></MobileHeader>

  <div v-show="!isLoading" class="container news-container has-space has-header">
	<h1 class="news_top-stories__title">{{topStoriesList.name}}</h1>
	<div v-if="!isMobile" class="news_top-stories__wrapper">
		<swiper :slidesPerView="3" :navigation = "{nextEl: '#btnSwiperNextRounded', prevEl: '#btnSwiperPrevRounded'}"  :spaceBetween="18" :freeMode="true" class="mySwiper ">
			<swiper-slide class="news_top-stories__container" v-for="(x, index) in topStoriesList.news" :key="index">
			 <router-link :to="{ name: 'newsDetail', params: { title: x.urlName, id: x.id, currentSportType: currentSportType }}">
				<div class="news_top-stories__img-wrapper">
					<img class="news_top-stories__img-coverImg" :src="`${s3ImgUrl}/${x.coverImage}`">
					<div class="news_top-stories__img-icon" v-if="x.videoFlag"><img src="../../../static/images/icons/icon_video_play.png"></div>
				</div>
				<div class="news_top-stories__desc-wrapper">
					<p class="news_top-stories__desc-title">{{x.title}}</p>
					<div class="news_top-stories__desc-tag">
						<span>{{x.publishedOn}}</span>
					</div>
				</div>
				</router-link>
			</swiper-slide>                     						
		</swiper> 
		<div id="btnSwiperNextRounded" v-if="isShowTopStoriesSwiper" class="news__top-stories-btn next" ><img class="swiper-button__img" src="../../../static/images/icons/icon_arrow_right_white.png"></div>
		<div id="btnSwiperPrevRounded" v-if="isShowTopStoriesSwiper" class="news__top-stories-btn prev"><img  class="swiper-button__img" src="../../../static/images/icons/icon_arrow_left_white.png"></div>
	</div>
	<div v-else>
		<div class="mb-3rem">
			<swiper  :pagination="{ clickable: true }" :autoplay='{"delay": 5000, "disableOnInteraction": false}' class="relative news_top-stories__swipper ">
				<swiper-slide class="news_top-stories__container" v-for="(x, index) in topStoriesList.news" :key="index">
					 <router-link :to="{ name: 'newsDetail', params: { title: x.urlName, id: x.id, currentSportType: currentSportType }}">
						<div class="news_top-stories__img-wrapper">
							<img class="news_top-stories__img-coverImg" :src="`${s3ImgUrl}/${x.coverImage}`">
							<div class="news_top-stories__img-icon" v-if="x.videoFlag"><img src="../../../static/images/icons/icon_video_play.png"></div>
						</div>
						<div class="news_top-stories__desc-wrapper">
							<p class="news_top-stories__desc-title">{{x.title}}</p>
							<div class="news_top-stories__desc-tag">
								<span>{{x.publishedOn}}</span>
							</div>
						</div>
					</router-link>
				</swiper-slide>                     						
			</swiper> 
		</div>
	</div>
	<div v-for="(o, index) in newsCateogoriesList" :key="index" class="news_latest-news">
		<div class="news_latest-news__title-wrapper">
			<h1 class="news_latest-news__title">{{o.name}}</h1>
			<div class="display-flex-center pointer">				  
				<router-link :to="{ name: 'newsCategories', params: {categoryName: o.urlName, categoryId: o.categoryId}}"  class="font-s mr-05rem">{{$t('SEE_MORE')}}</router-link>
				<img src="../../../static/images/icons/icon_arrow_right_white.png">
			</div>
		</div>
		<div class="news_latest-news__wrapper">
			    <NewCategoriesCard :data="o.news"> </NewCategoriesCard>
			<!-- <router-link :to="{ name: 'newsDetail', params: { title: x.urlName, id: x.id}}"   class="news_latest-news__container" v-for="(x, index) in o.news" :key="index">
				<div class="news_latest-news__container-img">
					<img :src="`${s3ImgUrl}/${x.coverImage}`">
				</div>
				<div class="news_latest-news__container-desc">
					<p>{{x.title}}</p>
					<div>
						<span class="news_latest-news__container-desc_tag">{{x.sportType}}</span>
						<span class="news_latest-news__container-desc_tag">{{x.soccerLeagueName}}</span>
						<span class="news_latest-news__container-desc_tag">{{x.publishedOn}}</span>
					</div>
				</div>
			</router-link> -->
		</div>
	</div>
  </div> 
    <Loading v-show="isLoading"></Loading>
  <!-- <div  class="home has-header hide" >
	  <div class="container has-space ">
		<h1 class="news_top-stories__title">{{topStoriesList.name}}</h1>
		<div class="mb-3rem">
			<swiper  :pagination="{ clickable: true }" :autoplay='{"delay": 50000, "disableOnInteraction": false}' class="relative news_top-stories__swipper ">
				<swiper-slide class="news_top-stories__container" v-for="(x, index) in topStoriesList.news" :key="index">
					<div class="news_top-stories__img-wrapper">
						<img class="news_top-stories__img-coverImg" :src="`${s3ImgUrl}/${x.coverImage}`">
					</div>
					<div class="news_top-stories__desc-wrapper">
						<p class="news_top-stories__desc-title">{{x.title}}</p>
						<div class="news_top-stories__desc-tag">
							<span>{{x.publishedOn}}</span>
						</div>
					</div>
				</swiper-slide>                     						
			</swiper> 
		</div>
		<div v-for="(o, index) in newsCateogoriesList" :key="index" class="news_latest-news">
			<div class="news_latest-news__title-wrapper">
				<h1 class="news_latest-news__title">{{o.name}}</h1>
				<div class="display-flex-center pointer">				  
					<router-link :to="{ name: 'newsCategories', params: {categoryName: o.urlName, categoryId: o.categoryId}}"  class="font-s mr-05rem">{{$t('SEE_MORE')}}</router-link>
					<img src="../../../static/images/icons/icon_arrow_right_white.png">
				</div>
			</div>
			<div class="news_latest-news__wrapper">
				<router-link :to="{ name: 'newsDetail', params: { title:x.urlName, id: x.id }}"   class="news_latest-news__container" v-for="(x, index) in o.news" :key="index">
					<div class="news_latest-news__container-img">
						<img :src="`${s3ImgUrl}/${x.coverImage}`">
					</div>
					<div class="news_latest-news__container-desc">
						<p class="news_latest-news__container-desc__title">{{x.title}}</p>
						<div class="display-flex-sb">
							<span class="tags single">{{x.soccerLeagueName}}</span>
							<span class="font-s">{{x.publishedOn}}</span>
						</div>
					</div>
				</router-link>
			</div>
		</div>
	  </div>
  </div> -->
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Pagination,Navigation,Autoplay} from 'swiper/core';
SwiperCore.use([Pagination,Navigation,Autoplay]);

import moment from 'moment'

import config from '@/js/config.js'
import MobileHeader from '@/components/mobile/Header.vue'
import NewCategoriesCard from '@/components/news/NewCategoriesCard.vue'
import {mapGetters,mapActions} from 'vuex'

export default {
	components:{
        Swiper,
        SwiperSlide,  
		MobileHeader,
		NewCategoriesCard     
    },
	watch:{
		$route() {
             this.getAllNewsListData();
        },
	},
	data() {
		return {
			s3ImgUrl: config.s3ImgUrl,
			newsCateogoriesList: [],
			topStoriesList: {},
			isShowTopStoriesSwiper :true,

			isLoading:true
		}
	},
	computed:{
        ...mapGetters([              
                'isMobile',
                'currentLocale',
				'currentSportType'
           ]),
    },
	mounted() {
		this.getAllNewsListData();
	},
	methods: {
		 ...mapActions([
            "getAllNewsList",
        ]),

		 async getAllNewsListData(){
        
            let params = {
                language: this.currentLocale
            }
          	this.isLoading =true;
            const result = await this.getAllNewsList(params);
			this.isLoading =false;
			let data = result.result;
			for (let i = 0; i<data.length; i++) {
					data[i].urlName = data[i].name.toLowerCase().replace(/\s/g, '-')
				for (let x = 0; x<data[i].news.length; x++) {
					let convertedDate;
					let A = moment(data[i].news[x].publishedOn);
					let B = moment();
					let diffDays = (A.diff(B, 'days'));
				
					if (diffDays > 0) {
						convertedDate =  moment(data[i].news[x].publishedOn).fromNow();
					} else {
						convertedDate  =  moment(data[i].news[x].publishedOn).format('dddd D MMM YYYY')
					}
					data[i].news[x].urlName = data[i].news[x].title.toLowerCase().replace(/\s/g, '-')
					data[i].news[x].publishedOn = moment(data[i].news[x].publishedOn).fromNow(); //moment("2019-09-10T07:53:06.000+00:00").fromNow(); //
				}
			}

			this.topStoriesList = data[0];
			if (this.topStoriesList.news.length > 3 ) {
				this.isShowTopStoriesSwiper = true;
			} else {
				this.isShowTopStoriesSwiper = false;
			}
		
			data.shift();

            this.newsCateogoriesList = data;
           
        },
	},
}
</script>

<style scoped>
.news-container{
	overflow: unset;
}
.news_latest-news{
	margin-bottom: 3rem;
}
.news_top-stories__title{
	font-size: 2.125rem;
	font-weight: 700;
	margin-bottom: 1.8rem;	
    margin-top: 1rem;

}
.news_top-stories__wrapper{
	position: relative;
	margin: 0 auto;
	margin-bottom: 2.5rem;
}
.news_top-stories__img-wrapper{
	padding-top: 58%;
	position: relative;
}
.news_top-stories__img-coverImg{
	object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.news_top-stories__img-icon{
	position: absolute;
    bottom: 1.25rem;
    left: 1.25rem;
}
.news__top-stories-btn {
	position: absolute;
    z-index: 2;
    top: 0;
    height: 100%;
    display: flex;
    width: 2rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.news__top-stories-btn.next{
	right: -2rem;
}
.news__top-stories-btn.prev{
	left: -2rem;
}
.news_top-stories__desc-wrapper{
	background-color: var(--color-theme);
	padding: 1rem;
}
.news_top-stories__desc-title{
	height: 2.5rem;
	font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 1rem;
	 display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.news_top-stories__desc-tag{
	font-size: 0.6rem;
}
.news_top-stories__desc-seperator{
	margin: 0 .5rem;
}

.news_latest-news__title-wrapper{
	display: flex;
	justify-content: space-between;
	border-bottom: .1rem solid var(--color-grey-60);
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}
.news_latest-news__title{
	font-size: 0.875rem;
	font-weight: 700;
}

.news_latest-news__container{
	display: flex;
	padding-bottom: 1rem;
	border-bottom: .1rem solid var(--color-grey-60);
		
}
.news_latest-news__container-img{
	margin-right: 1rem;
	position: relative;
    width: 13.31rem;
    height: 7.5rem;
}
.news_latest-news__container-img img{
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.news_latest-news__wrapper{
	display: grid;
	grid-template-columns: repeat(2,1fr);
	column-gap: 1rem;
    row-gap: 1rem
}
.news_latest-news__container-desc{
	padding: 1rem 1rem 1rem 0;
	display: flex;
	flex: 1;
    flex-direction: column;
    justify-content: space-between;
}
.news_latest-news__container-desc p{
	 display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
   
.news_latest-news__container-desc_tag{
	font-size: 0.6rem;
}
.news_latest-news__container-desc_tag::after{
	content: '|';
	width: .1rem;
	height: 1rem;
	margin: 0 .5rem;
}
.news_latest-news__container-desc_tag:last-of-type::after {
	display: none;
}
@media (max-width: 768px){
	.news-container{
		overflow: hidden;
	}
	.news_top-stories__img-wrapper{
		/* padding-top: 0;
		width: 10rem;
		height: 10rem; */
	}
	.news_top-stories__img-wrapper{

	}
	.news_top-stories__swipper{
		overflow: unset;
	}
	.news_top-stories__desc-title{
		margin-bottom: 1rem;
		height: 2rem;
		font-size: 0.875rem;
	}
	.news_top-stories__title{
		font-size: 0.875rem;
		font-weight: 700;
		margin-bottom: 0.75rem;
		margin-top: 1rem;
	}
	.news_top-stories__desc-wrapper{
   		/* padding: 0.5rem; */
	}


	.news_latest-news__wrapper{
	    display: flex;
    	flex-direction: column;
	}
	.news_latest-news__container-img{
		flex: 0 0 29%;
		padding-top: 19%;
		width: auto;
		height: auto;
	}
	.news_latest-news__container-desc{
		padding: 0;
	}
	.news_latest-news__title-wrapper{
		border-bottom: none;
	}
	.news_latest-news__container-desc__title{
		font-size: 0.875rem;
	}
}
</style>