<template> 
    <router-link :to="{ name: 'newsDetail', params: { title:o.urlName, id: o.id, currentSportType: currentSportType }}"   class="news__categories-card__container" v-for="(o, index) in data" :key="index">
        <div class="news__categories-card__container-img">
            <img :src="`${s3ImgUrl}/${o.coverImage}`" class="news__categories-card__container-img__coverImg">
            <div class="news__categories-card__container-img-icon" v-if="o.videoFlag"><img src="../../../static/images/icons/icon_video_play.png"></div>
        </div>
        <div class="news__categories-card__container-desc">
            <p class="news__categories-card__container-desc__title">{{o.title}}</p>
            <div class="display-flex-sb">
                <div class=" news__categories-card__tag-wrapper" v-if="!isMobile">
                    <span class="tags" v-for="(x, index) in o.newsTags" :key="index">{{x.name}}</span>
                </div>
                <div v-else>
                    <span class="tags single" v-show="index === 0" v-for="(x, index) in o.newsTags" :key="index">{{x.name}}</span>
                </div>
                
                <span class="font-s news__categories-card__time">{{o.publishedOn}}</span>
            </div>
        </div>
    </router-link>
</template>

<script>
import config from '@/js/config.js'
import {mapGetters,mapActions} from 'vuex'
export default {
    props:{
        data: {
            type: Object
        },
    },
     computed:{
        ...mapGetters([              
                'isMobile',
                'currentSportType'
           ]),
    },
    data() {
        return {
            s3ImgUrl: config.s3ImgUrl,
        }
    },
}
</script>

<style scoped>
.news__categories-card__title-wrapper{
	display: flex;
	justify-content: space-between;
	border-bottom: .1rem solid var(--color-grey-60);
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}
.news__categories-card__title{
	font-size: 0.875rem;
	font-weight: 700;
}
.news__categories-card__time{
    flex: 0 0 5rem;
    text-align: right;
}
.news__categories-card__tag-wrapper{
    overflow: hidden;
    height: 1.6rem;
}
.news__categories-card__container{
	display: flex;
	padding-bottom: 1rem;
    margin-bottom: 1rem;
	border-bottom: .1rem solid var(--color-grey-60);
		
}
.news__categories-card__container-img{
	margin-right: 1rem;
	position: relative;
    width: 13.31rem;
    height: 7.5rem;
}
.news__categories-card__container-img__coverImg{
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.news__categories-card__container-img-icon{
    position: absolute;
    bottom: 0.67rem;
    left: 0.67rem;
    width: 1.87rem;
    height: 1.87rem;
}
.news__categories-card__container-img-icon img{
    width: 100%;
    height: 100%;
}
.news__categories-card__wrapper{
	display: flex;
flex-direction: column;
	column-gap: 1rem;
    row-gap: 1rem
}
.news__categories-card__container-desc{
	padding: 1rem 0 1rem 0;
	display: flex;
	flex: 1;
    flex-direction: column;
    justify-content: space-between;
}
.news__categories-card__container-desc p{
	 display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
   
.news__categories-card__container-desc_tag{
	font-size: 0.6rem;
}
.news__categories-card__container-desc_tag::after{
	content: '|';
	width: .1rem;
	height: 1rem;
	margin: 0 .5rem;
}
.news__categories-card__container-desc_tag:last-of-type::after {
	display: none;
}
@media (max-width: 768px){
    	.news__categories-card__wrapper{
	    display: flex;
    	flex-direction: column;
	}
	.news__categories-card__container-img{
		flex: 0 0 29%;
		padding-top: 19%;
		width: auto;
		height: auto;
	}
	.news__categories-card__container-desc{
		padding: 0;
	}
	.news__categories-card__title-wrapper{
		border-bottom: none;
	}
	.news__categories-card__container-desc__title{
		font-size: 0.875rem;
	}
}
</style>